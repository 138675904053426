<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          label="菜品名称"
          v-model="searchParam.foodName"
          placeholder="请输入菜品名称"
        ></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="edit(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      :title="dialogTitle"
      v-model="dialogFormVisible"
      sureTxt="保存"
      cancelTxt="返回"
      @confirm="submit"
      @open="OnOpen"
    >
      <form-panel
        ref="formList"
        :form="form"
        :hasHeader="false"
        :footerShow="false"
      >
        <el-form-item
          label="菜品名称"
          prop="foodName"
          :rules="[
            {
              required: true,
              message: '请输入菜品名称',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            placeholder="请输入菜品名称"
            clearable
            class="filter-item"
            v-model="form.foodName"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="图片"
          prop="image"
          :rules="[
            {
              required: true,
              message: '请上传图片',
              trigger: 'blur',
            },
          ]"
        >
          <v-upload
            :limit="1"
            :imgUrls.sync="form.image"
            tips="建议上传正方形图片"
          />
        </el-form-item>
        <el-form-item
          label="定价"
          prop="price"
          :rules="[
            {
              required: true,
              message: '请输入定价',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            placeholder="请输入定价(单位：元)"
            clearable
            type="number"
            class="filter-item"
            v-model="form.price"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="优惠价"
          prop="discount"
          :rules="[
            {
              required: true,
              message: '请输入优惠价',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            placeholder="请输入优惠价"
            clearable
            type="number"
            class="filter-item"
            v-model="form.discount"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="菜品类别"
          prop="foodType"
          :rules="[
            {
              required: true,
              message: '请选择菜品类别',
              trigger: 'change',
            },
          ]"
        >
          <v-select
            clearable
            placeholder="请选择菜品类别"
            :options="foodTypelist"
            v-model="form.foodType"
          />
        </el-form-item>
        <el-form-item
          label="正餐"
          prop="foodTime"
          :rules="[
            {
              required: true,
              message: '请输入菜品介绍',
              trigger: 'change',
            },
          ]"
        >
          <v-radio :radioObj="radioObj" v-model="form.foodTime"></v-radio>
        </el-form-item>
        <el-form-item
          label="菜品介绍"
          prop="content"
          :rules="[
            {
              required: true,
              message: '请输入菜品介绍',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            placeholder="请输入菜品介绍"
            clearable
            class="filter-item"
            type="textarea"
            v-model="form.content"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="是否"
          prop="isRequired"
          :rules="[
            {
              required: true,
              message: '请输入菜品介绍',
              trigger: 'blur',
            },
          ]"
        >
          <v-radio
            :radioObj="isRequiredOpt"
            v-model="form.isRequired"
          ></v-radio>
        </el-form-item>
      </form-panel>
    </v-dialog>
  </div>
</template>

<script>
import {
  getFoodListUrl,
  deleteFoodInfoUrl,
  getFoodTypeUrl,
  addOrUpdateFoodInfoUrl,
} from "./api.js";
import {} from "./map.js";
import { Message, MessageBox } from "element-ui";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
export default {
  name: "foodManage",
  data() {
    return {
      radioObj: [
        { name: 1, value: "午餐" },
        { name: 2, value: "晚餐" },
      ],
      isRequiredOpt: [
        { name: 0, value: "非必选" },
        { name: 1, value: "必选" },
      ],
      form: {
        id: null,
        foodName: null,
        image: "",
        foodType: null,
        price: null,
        content: null,
        discount: "",
        foodTime: 1,
        isRequired: 0,
      },
      dialogTitle: "新增",
      dialogFormVisible: false,
      foodTypelist: [],
      tableUrl: getFoodListUrl,
      searchParam: {
        foodName: null,
      },
      headers: [
        {
          prop: "id",
          label: "菜品id",
        },
        {
          prop: "foodType",
          label: "菜品类型",
        },
        {
          prop: "foodName",
          label: "菜品名称",
        },
        {
          prop: "image",
          label: "图片",
          formatter: (row, prop) =>
            createImgVNode(this, row, prop) || "暂无图片",
        },
        {
          prop: "price",
          label: "定价",
          formatter: (row, prop) => {
            return row[prop] ? "￥" + row[prop] : "--";
          },
        },
        {
          prop: "discount",
          label: "优惠价",
          formatter: (row, prop) => {
            return row[prop] ? "￥" + row[prop] : "--";
          },
        },
        {
          prop: "foodTime",
          label: "正餐",
          formatter: (row, prop) => {
          if ([null, undefined, ""].includes(row[prop])) {
              return "--";
            } else {
              return row[prop] == 1 ? "午餐" : "晚餐";
            }
          },
        },
        {
          prop: "isRequired",
          label: "是否必选",
          formatter: (row, prop) => {
            if ([null, undefined, ""].includes(row[prop])) {
              return "--";
            } else {
              return row[prop] == 0 ? "非必选" : "必选";
            }
          },
        },
      ],
    };
  },
  created() {
    this.getFoodTypeList();
  },
  methods: {
    getFoodTypeList() {
      let params = {
        tenantId: "0",
      };
      this.$axios.get(`${getFoodTypeUrl}`, { params: params }).then((res) => {
        if (res.code == 200) {
          res.data.forEach((item) => {
            item = { value: item, name: item };
            this.foodTypelist.push(item);
          });
          console.log(this.foodTypelist);
        }
      });
    },
    back() {
      this.dialogFormVisible = false;
    },
    OnOpen() {
      this.$nextTick(() => {
        if (this.$refs.formList) {
          this.$refs.formList.clearValidate();
        }
      });
    },
    submit() {
      const result = this.$refs.formList.validate();
      if (result) {
        this.$axios.post(`${addOrUpdateFoodInfoUrl}`, this.form).then((res) => {
          if (res.code == 200) {
            this.dialogFormVisible = false;
            this.$message.success("操作成功！");
            this.$refs.list.search();
          }
        });
      }
    },
    edit(item) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = item[key];
      });
      this.dialogFormVisible = true;
    },
    toAdd() {
      for (let key in this.form) {
        this.$set(this.form, key, "");
      }
      this.dialogFormVisible = true;
    },
    // 删除
    toDelete(item) {
      MessageBox.confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: item.id,
          };
          this.$axios
            .post(`${deleteFoodInfoUrl}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
