var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('v-list',{ref:"list",attrs:{"isAdd":true,"headers":_vm.headers,"tableUrl":_vm.tableUrl,"searchParam":_vm.searchParam},scopedSlots:_vm._u([{key:"headBtnSlot",fn:function(){return [_c('v-button',{attrs:{"text":"新增"},on:{"click":_vm.toAdd}})]},proxy:true},{key:"searchSlot",fn:function(){return [_c('v-input',{attrs:{"label":"菜品名称","placeholder":"请输入菜品名称"},model:{value:(_vm.searchParam.foodName),callback:function ($$v) {_vm.$set(_vm.searchParam, "foodName", $$v)},expression:"searchParam.foodName"}})]},proxy:true},{key:"operateSlot",fn:function(scope){return [_c('v-button',{attrs:{"text":"编辑","type":"text"},on:{"click":function($event){return _vm.edit(scope.row)}}}),_c('v-button',{attrs:{"text":"删除","type":"text"},on:{"click":function($event){return _vm.toDelete(scope.row)}}})]}}])}),_c('v-dialog',{attrs:{"title":_vm.dialogTitle,"sureTxt":"保存","cancelTxt":"返回"},on:{"confirm":_vm.submit,"open":_vm.OnOpen},model:{value:(_vm.dialogFormVisible),callback:function ($$v) {_vm.dialogFormVisible=$$v},expression:"dialogFormVisible"}},[_c('form-panel',{ref:"formList",attrs:{"form":_vm.form,"hasHeader":false,"footerShow":false}},[_c('el-form-item',{attrs:{"label":"菜品名称","prop":"foodName","rules":[
          {
            required: true,
            message: '请输入菜品名称',
            trigger: 'blur',
          },
        ]}},[_c('v-input',{staticClass:"filter-item",attrs:{"placeholder":"请输入菜品名称","clearable":""},model:{value:(_vm.form.foodName),callback:function ($$v) {_vm.$set(_vm.form, "foodName", $$v)},expression:"form.foodName"}})],1),_c('el-form-item',{attrs:{"label":"图片","prop":"image","rules":[
          {
            required: true,
            message: '请上传图片',
            trigger: 'blur',
          },
        ]}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.image,"tips":"建议上传正方形图片"},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "image", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "image", $event)}}})],1),_c('el-form-item',{attrs:{"label":"定价","prop":"price","rules":[
          {
            required: true,
            message: '请输入定价',
            trigger: 'blur',
          },
        ]}},[_c('v-input',{staticClass:"filter-item",attrs:{"placeholder":"请输入定价(单位：元)","clearable":"","type":"number"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1),_c('el-form-item',{attrs:{"label":"优惠价","prop":"discount","rules":[
          {
            required: true,
            message: '请输入优惠价',
            trigger: 'blur',
          },
        ]}},[_c('v-input',{staticClass:"filter-item",attrs:{"placeholder":"请输入优惠价","clearable":"","type":"number"},model:{value:(_vm.form.discount),callback:function ($$v) {_vm.$set(_vm.form, "discount", $$v)},expression:"form.discount"}})],1),_c('el-form-item',{attrs:{"label":"菜品类别","prop":"foodType","rules":[
          {
            required: true,
            message: '请选择菜品类别',
            trigger: 'change',
          },
        ]}},[_c('v-select',{attrs:{"clearable":"","placeholder":"请选择菜品类别","options":_vm.foodTypelist},model:{value:(_vm.form.foodType),callback:function ($$v) {_vm.$set(_vm.form, "foodType", $$v)},expression:"form.foodType"}})],1),_c('el-form-item',{attrs:{"label":"正餐","prop":"foodTime","rules":[
          {
            required: true,
            message: '请输入菜品介绍',
            trigger: 'change',
          },
        ]}},[_c('v-radio',{attrs:{"radioObj":_vm.radioObj},model:{value:(_vm.form.foodTime),callback:function ($$v) {_vm.$set(_vm.form, "foodTime", $$v)},expression:"form.foodTime"}})],1),_c('el-form-item',{attrs:{"label":"菜品介绍","prop":"content","rules":[
          {
            required: true,
            message: '请输入菜品介绍',
            trigger: 'blur',
          },
        ]}},[_c('v-input',{staticClass:"filter-item",attrs:{"placeholder":"请输入菜品介绍","clearable":"","type":"textarea"},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('el-form-item',{attrs:{"label":"是否","prop":"isRequired","rules":[
          {
            required: true,
            message: '请输入菜品介绍',
            trigger: 'blur',
          },
        ]}},[_c('v-radio',{attrs:{"radioObj":_vm.isRequiredOpt},model:{value:(_vm.form.isRequired),callback:function ($$v) {_vm.$set(_vm.form, "isRequired", $$v)},expression:"form.isRequired"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }